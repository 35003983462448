import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routers = [
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
    props: {},
  },
  {
    path: '/updateserver',
    name: 'updateserver',
    component: () => import('../views/welcome/ServerIsUpdating.vue'),
    props: {},
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/welcome/CreateCompany.vue'),
    props: {},
  },
  {
    path: '/activeemployee',
    name: 'activeemployee',
    component: () => import('../views/welcome/ActiveEmployee.vue'),
    props: {},
  },
  {
    path: '/activeemployeedone',
    name: 'activeemployeedone',
    component: () => import('../views/welcome/ActiveEmployeeDone.vue'),
    props: {},
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/welcome/Register.vue'),
    props: {},
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/welcome/Login.vue'),
    props: {},
  },
  {
    path: '/accuracy-gmail',
    name: 'login',
    component: () => import('../views/welcome/AccuracyLoginGmail.vue'),
    props: {},
  },
  {
    path: '/twofactorauthen',
    name: 'twofactorauthen',
    component: () => import('../views/welcome/TwoFactorAuthen.vue'),
    props: {},
  },
  {
    path: '/forgotpass',
    name: 'forgotpass',
    component: () => import('../views/welcome/ForgotPassword.vue'),
    props: {},
  },
  {
    path: '/forgotpassdone',
    name: 'forgotpassdone',
    component: () => import('../views/welcome/ForgotPasswordDone.vue'),
    props: {},
  },
  {
    path: '/resetpass',
    name: 'resetpass',
    component: () => import('../views/welcome/ResetPassword.vue'),
    props: {},
  },
  {
    path: '/link-expired',
    name: 'codeexpired',
    component: () => import('../views/welcome/CodeExpired.vue'),
    props: {},
  },
  {
    path: '/Confirmemail',
    name: 'Confirmemail',
    component: () => import('../views/welcome/Confirmemail.vue'),
    props: {},
  },
  {
    path: '/SubscriptionPayment',
    name: 'SubscriptionPayment',
    component: () => import('../views/welcome/SubscriptionPayment.vue'),
    props: {},
  },
  {
    path: '/GoCardlessDebitSuccessUrl',
    name: 'GoCardlessDebitSuccessUrl',
    component: () => import('../views/home/settings/components/GoCardlessDebitSuccessUrl.vue'),
    props: {},
  },
  {
    path: '/base',
    name: 'base',
    component: () => import('../views/home/base/BaseLayout.vue'),
    props: {},
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/home/dashboard/Dashboard.vue'),
        props: {},
      },
      {
        path: '/payrolls',
        name: 'payrolls',
        component: () => import('../views/home/runpayroll/RunOverview.vue'),
        props: {},
      },
      {
        path: '/runpayroll',
        name: 'runpayroll',
        component: () => import('../views/home/runpayroll/CreateRunPayRoll.vue'),
        props: {},
      },
      {
        path: '/updateRunpayroll',
        name: 'updateRunpayroll',
        component: () => import('../views/home/runpayroll/UpdateRunPayRoll.vue'),
        props: {},
      },
      {
        path: '/employees',
        name: 'employees',
        component: () => import('../views/home/employees/People.vue'),
        props: {},
      },
      {
        path: '/leave',
        name: 'leave',
        component: () => import('../views/home/timesheet/Leave.vue'),
        props: {},
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('../views/home/reports/Reports.vue'),
        props: {},
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('../views/home/settings/Settings.vue'),
        props: {},
      },
      {
        path: '/companies',
        name: 'companies',
        component: () => import('../views/home/company/ListCompany.vue'),
        props: {},
      }
    ]
  },
  { path: '*', redirect: '/register' }, // catch all use case
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes: routers
})

export default router
