import ApiManager from "@/api/ApiManager";
import { SEARCH_BUSINESS, DETAIN_BUSINESS } from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
  [SEARCH_BUSINESS](context, dataRequest) {
    return new Promise((success, reject) => {
      var keySearch = ""
      var abn = dataRequest.keySearch.split(" ").join("")
      
      if (!isNaN(abn) && abn.length == 11) {
        keySearch = dataRequest.keySearch.split(" ").join("")
      } else {
        keySearch = dataRequest.keySearch
      }
      ApiManager.get("/api/Business/Search?keySearch=" + keySearch + "&page=" + dataRequest.page + "&lenght=" + dataRequest.lenght)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DETAIN_BUSINESS](context, keyAbn) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Business/Detail?keyABN=" + keyAbn)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
