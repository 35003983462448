import ApiManager from "@/api/ApiManager";
import {
  GET_EMPLOYEE,
  GET_INDIVIDUAL_EMPLOYEE,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_BY_EMAIL,
  ADD_EMPLOYEE_DEMO,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_BY_EMAIL,
  DETAIL_LEAVE,
  CHECK_EMAIL,
  CHECK_CONTACT_NUMBER,
  CHECK_TAX_NUMBER,
  DATA_STEP0,
  DATA_STEP1,
  DATA_STEP2,
  DATA_STEP3,
  DATA_STEP4,
  DELETEDATA
} from "../actionsType.js";

// initial state
const state = {
  listEmployee: [],
  // Step0
  startDate: "",
  firstName: "",
  lastName: "",
  job: "",
  email: "",
  companyId: 0,
  // Step 1
  address1: "",
  address2: "",
  suburb: "",
  postcode: "",
  state: "",
  country: "",
  countryCode: "au",
  contactNumber: "",

  // Step 2
  bank: {
    paymentMethod: "",
    bankAccountName: "",
    bankAccountBSB: "",
    bankAccountNumber: ""
  },
  fund: {
    superFundType: "",
    contributionRate: 0.0,
    fundName: "",
    fundABN: "",
    fundAddress: "",
    fundUSI: "",
    fundEmployerNumber: "",
    memberNumber: "",
    fundBankName: "",
    fundBankBSB: "",
    fundBankNumber: ""
  },
  // Step 3
  birthDate: "",
  statusResidency: "",
  isExemption: true,
  tfnExemption: "",
  taxNumber: "",
  hasTaxTemplate: false,
  taxTemplate: 0,

  // Step 4
  employmentType: "",
  weeklyWorkHours: 0,
  leaveLoading: 0,
  casualLoading: 25,
  payRatePerHour: 0,
  payRate: 0,
  typeRate: "PerHour",
  payPeriod: "",
  // Undefined
  gender: null, // ["Male", "FeMale", ""Indeterminate, null]
  annualLeaveBalance: 0,
  sickLeaveBalance: 0
};

// getters
const getters = {};

// actions
const actions = {
  [GET_EMPLOYEE](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Employee/List?companyId=" + companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_INDIVIDUAL_EMPLOYEE](context, employeeId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Employee/IndividualEmployee?employeeId=" + employeeId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_EMPLOYEE]() {
    return new Promise((success, reject) => {
      var requestModel = {
        // Step0
        "startDate": this.state.startDate,
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "job": this.state.job,
        "email": this.state.email,
        //step1
        "address1": this.state.address1,
        "address2": this.state.address2,
        "suburb": this.state.suburb,
        "postcode": this.state.postcode,
        "state": this.state.state,
        "country": this.state.country,
        "contactNumber": this.state.contactNumber,
        //step2
        "bank": this.state.bank,
        "fund": this.state.fund,
        // step3
        "birthDate": this.state.birthDate,
        "statusResidency": this.state.statusResidency,
        "claimTaxFreeThreshold": this.state.claimTaxFreeThreshold,
        "hasHecsOrHelpDebt": this.state.hasHecsOrHelpDebt,
        "hasTslOrStudentStart": this.state.hasTslOrStudentStart,
        "hasFinancialSupplementDebt": this.state.hasFinancialSupplementDebt,
        "taxFileNumberDeclaration": this.state.taxFileNumberDeclaration,
        "isExemption": this.state.isExemption,
        "tfnExemption": this.state.tfnExemption,
        "taxNumber": this.state.taxNumber,
        "taxTemplate": this.state.taxTemplate,
        "hasTaxTemplate": this.state.hasTaxTemplate,
        // step4
        "weeklyWorkHours": this.state.weeklyWorkHours,
        "leaveLoading": this.state.leaveLoading,
        "casualLoading": this.state.casualLoading,
        "payRatePerHour": this.state.payRatePerHour,
        "payRate": this.state.payRate,
        "typeRate": this.state.typeRate,
        "payPeriod": this.state.payPeriod,
        "employmentType": this.state.employmentType,
        // Undefined
        "id": 0,
        "companyId": this.state.companyId,
        "active": "ACTIVE",
        "gender": this.state.gender,
        "annualLeaveBalance": this.annualLeaveBalance,
        "sickLeaveBalance": this.sickLeaveBalance,
        "listExtraHours": [],
        "ListAllowance": [],
        "listDeduction": [],
        // "superannuation": null,
        "listBonous": [],
        "listTermination": [],
        "grossEarningsBalance": 0,
        "taxBalance": 0,
        "superGuaranteeBalance": 0,
        "employerContributionBalance": 0,
        "superGuarantee": 0,
        "employerContribution": 0,
        "superSalarySacrifice": 0,
        "notes": null,
        'terminationDate': null
      }
      
      ApiManager.put("/api/employee/add", requestModel)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_EMPLOYEE_BY_EMAIL](context, callbackUrl) {
    return new Promise((success, reject) => {
      var requestModel = {
        // Step0
        "startDate": this.state.startDate,
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "job": this.state.job,
        "email": this.state.email,
        // step4
        "weeklyWorkHours": this.state.weeklyWorkHours,
        "leaveLoading": this.state.leaveLoading,
        "casualLoading": this.state.casualLoading,
        "payRatePerHour": this.state.payRatePerHour,
        "payRate": this.state.payRate,
        "typeRate": this.state.typeRate,
        "payPeriod": this.state.payPeriod,
        "employmentType": this.state.employmentType,
        // Undefined
        "id": 0,
        "companyId": this.state.companyId,
        "active": "PENDING",
        "gender": this.state.gender,
        'terminationDate': null,
        "country": "au"
      }
      
      ApiManager.post("/api/Employee/AddByEmail?callbackUrl="+ callbackUrl, requestModel)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_EMPLOYEE_DEMO](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.put("/api/Employee/AddDemo?companyId="+ companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_EMPLOYEE](context, request) {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/Employee/Delete?companyId=" + request.companyId + "&employeeId=" + request.employeeId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_EMPLOYEE](context, dataUpdate) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Employee/Update", dataUpdate)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_EMPLOYEE_BY_EMAIL](context, dataUpdate) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Employee/UpdateByEmail?codeEncode="+ dataUpdate.code, dataUpdate.dataRequest)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DETAIL_LEAVE](context, employeeId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Employee/DetailLeave?employeeId=" + employeeId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_EMAIL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Employee/CheckEmail?companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&email=" + request.email)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_CONTACT_NUMBER](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Employee/CheckContactNumber?companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&contactNumber=" + request.contactNumber)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_TAX_NUMBER](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Employee/CheckTaxNumber?companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&taxNumber=" + request.taxNumber)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DATA_STEP0](context, data) {
    this.state.startDate = data.startDate;
    this.state.firstName = data.firstName;
    this.state.lastName = data.lastName;
    this.state.job = data.job;
    this.state.email = data.email;
    this.state.companyId = data.companyId;
  },
  [DATA_STEP1](context, data) {
    this.state.address1 = data.address1;
    this.state.address2 = data.address2;
    this.state.suburb = data.suburb;
    this.state.postcode = data.postcode;
    this.state.state = data.state;
    this.state.country = data.countryCode;  // watch
    this.state.contactNumber = data.contactNumber;
  },
  [DATA_STEP2](context, data) {
    var paymentMethod = data.paymentMethod;
    var bankAccountName = data.bankAccountName;
    var bankAccountBSB = data.bankAccountBSB;
    var bankAccountNumber = data.bankAccountNumber;

    this.state.bank = { paymentMethod, bankAccountName, bankAccountBSB, bankAccountNumber }; // watch

    this.annualLeaveBalance = 0; // watch
    this.sickLeaveBalance = 0; // watch

    var superFundType = data.superFundType;
    var contributionRate = data.contributionRate;
    var fundName = data.fundName;
    var fundABN = data.fundABN;
    var fundAddress = data.fundAddress;
    var fundUSI = data.fundUSI;
    var fundEmployerNumber = data.fundEmployerNumber;
    var memberNumber = data.memberNumber;
    var fundBankName = data.fundBankName;
    var fundBankBSB = data.fundBankBSB;
    var fundBankNumber = data.fundBankNumber;
    this.state.fund = {
      superFundType,
      contributionRate,
      fundName,
      fundABN,
      fundAddress,
      fundUSI,
      fundEmployerNumber,
      memberNumber,
      fundBankName,
      fundBankBSB,
      fundBankNumber
    };
  },
  [DATA_STEP3](context, data) {
    this.state.birthDate = data.birthDate;
    this.state.statusResidency = data.statusResidency;

    this.state.claimTaxFreeThreshold = data.claimTaxFreeThreshold;
    this.state.hasHecsOrHelpDebt = data.hasHecsOrHelpDebt;
    this.state.hasTslOrStudentStart = data.hasTslOrStudentStart;
    this.state.hasFinancialSupplementDebt = data.hasFinancialSupplementDebt;
    this.state.taxFileNumberDeclaration = data.taxFileNumberDeclaration;
    // Tax
    this.state.isExemption = data.isExemption;
    this.state.tfnExemption = data.tfnExemption;
    this.state.taxNumber = data.taxNumber;
    this.state.hasTaxTemplate = data.hasTaxTemplate;
    this.state.taxTemplate = data.taxTemplate;
  },
  [DELETEDATA]() {
    // Step 1
    this.state.address1 = "";
    this.state.address2 = "";
    this.state.suburb = "";
    this.state.postcode = "";
    this.state.state = "";
    this.state.contactNumber = "";
    this.state.country = "";

    // Step 2
    this.state.bank = { paymentMethod: "", bankAccountName: "", bankAccountBSB: "", bankAccountNumber: ""};
    this.annualLeaveBalance = 0;
    this.sickLeaveBalance = 0;
    this.state.fund = {
      superFundType: "",
      contributionRate: 0.0,
      fundName: "",
      fundABN: "",
      fundAddress: "",
      fundUSI: "",
      fundEmployerNumber: "",
      memberNumber: "",
      fundBankName: "",
      fundBankBSB: "",
      fundBankNumber: ""
    };

    // Step 3
    this.state.birthDate = "";
    this.state.statusResidency = "";
    
    this.state.claimTaxFreeThreshold = true;
    this.state.hasHecsOrHelpDebt = false;
    this.state.hasTslOrStudentStart = false;
    this.state.hasFinancialSupplementDebt = false;
    this.state.taxFileNumberDeclaration = false;

    this.state.isExemption = true;
    this.state.tfnExemption = "";
    this.state.taxNumber = "";
    this.state.hasTaxTemplate = false;
    this.state.taxTemplate = 0;
  },

  [DATA_STEP4](context, data) {
    this.state.employmentType = data.employmentType;
    this.state.weeklyWorkHours = data.weeklyWorkHours;
    this.state.leaveLoading = data.leaveLoading;
    this.state.casualLoading = data.casualLoading;
    this.state.payRatePerHour = data.payRatePerHour;
    this.state.payRate = data.payRate;
    this.state.typeRate = data.typeRate;
    this.state.payPeriod = data.payPeriod;
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
