import ApiManager from "@/api/ApiManager";
import { ACCOUNTANTS, DELETE_ACCOUNTANT, ADD_ACCOUNTANT_BY_EMAIL, ADD_ACCOUNTANT } from "../actionsType.js";
// initial state
const state = {
};

// getters
const getters = {
};
const actions = {
    [ACCOUNTANTS](context, companyId) {
        return new Promise((success, reject) => {
          ApiManager.post("/api/Invided/List?companyId="+companyId)
            .then((response) => {
              success(response)
            })
            .catch((error) => {
              reject(error);
            });
        });
    },
    [ADD_ACCOUNTANT_BY_EMAIL](context, param) {
        return new Promise((success, reject) => {
          ApiManager.post("/api/Invided/UserInvidedCompanyByEmail?callbackUrlLogin="+param.callbackUrlLogin+"&callbackUrlRegistry="+param.callbackUrlRegistry+"&companyId="+param.companyId+"&email="+param.email)
            .then((response) => {
              success(response)
            })
            .catch((error) => {
              reject(error);
            });
        });
    },
    [ADD_ACCOUNTANT](context, param) {
        return new Promise((success, reject) => {
          ApiManager.post("/api/Invided/InvidedCompanyFromUser?codeEncode="+param.codeEncode+"&companyId="+param.companyId+"&email="+param.email)
            .then((response) => {
              success(response)
            })
            .catch((error) => {
              reject(error);
            });
        });
    },
    [DELETE_ACCOUNTANT](context, param) {
        return new Promise((success, reject) => {
          ApiManager.delete("/api/Invided/Delete?companyId="+param.companyId+"&email="+param.email)
            .then((response) => {
              success(response)
            })
            .catch((error) => {
              reject(error);
            });
        });
    },
}

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};