import ApiManager from "@/api/ApiManager";
import { REGISTER_ACCOUNT, LOGIN, SEND_EMAIL_COMFIRM, CHECK_TOKEN_REGISTER, COMFIRM_EMAIL, SEND_EMAIL_FORGOT_PASSWORD, CHECK_TOKEN_FORGOT_PASSWORD, FORGOT_PASSWORD_EMAIL, LOGOUT, LOGIN_WITH_GOOGLE, CHECK_GOOGLE_REGISTER, CHECK_CODE_ENCODE} from "../actionsType.js";
import Token from "@/api/Token";

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
  [REGISTER_ACCOUNT](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/register", request)
        .then((response) => {
          Token.saveToken(response.accessToken);
          Token.saveRefreshToken(response.refreshToken);
          success(response)
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LOGIN](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/login", request)
        .then((response) => {
          Token.saveToken(response.accessToken);
          Token.saveRefreshToken(response.refreshToken);
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LOGOUT](context, refreshToken_) {
    return new Promise((success, reject) => {
      let formData = new FormData();
      formData.append('refreshToken', refreshToken_)
      ApiManager.post("/api/Auth/logout", formData)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_EMAIL_COMFIRM](context, callbackUrl) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/SendEmailRegister?callbackUrl=" + callbackUrl, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_TOKEN_REGISTER](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/CheckTokenRegister?userId=" + request.userId + "&token=" + request.code, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [COMFIRM_EMAIL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post(`/api/Auth/ConfirmEmailRegister?userId=${request.userId}&token=${request.code}`, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_EMAIL_FORGOT_PASSWORD](context, request) {
    return new Promise((success, reject) => {
      const encodedEmail = encodeURIComponent(request.email);
      const encodedUrl = encodeURIComponent(request.callbackUrl);
      ApiManager.post(`/api/Auth/SendEmailForgotPassword?callbackUrl=${encodedUrl}&email=${encodedEmail}`, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_TOKEN_FORGOT_PASSWORD](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/CheckTokenForgotPassword?userId=" + request.userId + "&token=" + request.code, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FORGOT_PASSWORD_EMAIL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/ConfirmEmailForgotPassword?userId=" + request.userId + "&token=" + request.code + "&newPassword=" + request.newPassword, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LOGIN_WITH_GOOGLE](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/google", request)
        .then((response) => {
          Token.saveToken(response.accessToken);
          Token.saveRefreshToken(response.refreshToken);
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_GOOGLE_REGISTER](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Auth/GoogleCheck", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHECK_CODE_ENCODE](context, code) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Employee/CheckCodeEncodeByEmail?codeEncode=" + code)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
