import ApiManager from "@/api/ApiManager";
import {
    GET_LIST_EMPLOYEE_LEAVE,
    GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY,
    ADD_LEAVE,
    UPDATE_LEAVE,
    GET_LIEST_LEAVE,
    GET_EMPLOYEELIST_LEAVE,
    DELETE_LEAVE,
    GET_LIST_HOLIDAYS
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters  = {};

// actions
const actions = {
  [GET_LIST_EMPLOYEE_LEAVE](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Leave/ListEmployee?companyId=" + companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },[GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY](context, employeeID) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Leave/EmployeeLeaveData?employeeID=" + employeeID)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_LEAVE](context, rejectModel) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Leave/Add", rejectModel)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LIEST_LEAVE](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Leave/List?companyId=" + companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_LEAVE](context, leaveId) {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/Leave/Delete?leaveId=" + leaveId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_LEAVE](context, rejectModel) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Leave/Update", rejectModel)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LIST_HOLIDAYS](  context, year) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Leave/ListHolidays2", year)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_EMPLOYEELIST_LEAVE](context, employeeId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Leave/employeeList?EmployeeId=" + employeeId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
