import ApiManager from "@/api/ApiManager";
import {CHECK_CODE, GET_CODE, VERIFY_CODE, REMOVE_CODE, RESET_PASSWORD, UPDATE_PROFILE, USER_INFO} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
  [USER_INFO]() {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Profile/UserInfo")
        .then((response) => {
          success(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  [CHECK_CODE]() {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Profile/Check2FA", {})
        .then((response) => {
          success(response)
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_CODE]() {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Profile/GetCode2FA", {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_CODE](context, authCode) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Profile/VerifyCode2FA?authenticatorCode=" + authCode, {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REMOVE_CODE]() {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/Profile/Remove2FA", {})
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RESET_PASSWORD](context,request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Profile/ChangePassword", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_PROFILE](context,request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Profile/Update", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
