import ApiManager from "@/api/ApiManager";
import {
    SAVE_DEVICE,
    REMOVE_DEVICE,
    LIST_DEVICE,
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
  [SAVE_DEVICE](context, userid) {
    return new Promise((success, reject) => {
      ApiManager.saveDevice("/api/Notification/SaveDevice?userId=" + userid)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REMOVE_DEVICE](context, userid) {
    return new Promise((success, reject) => {
      ApiManager.removeDevice("/api/Notification/RemoveDevice?userId=" + userid+ "&deviceId=" + ApiManager.getDeviceId())
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LIST_DEVICE]() {
    return new Promise((success, reject) => {
      ApiManager.post("/api/Notification/ListDevice")
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
