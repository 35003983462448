import Vue from "vue"
import * as Session from './SessionStore';
import ApiManager from "@/api/ApiManager";
import * as Logs from "./Logs";
var typeCode = require('crypto-js');
var listUrlKeyDefault = [
        "/api/Auth/Handshake?isValid=true",
        "/api/Auth/IpClient",
        "/api/Auth/OperatingSystem",
    ];
//encryptText, planeText

function encrypt(planeText) {
    try {
        var cipher = typeCode.AES.encrypt(planeText, typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_KEY), {
            iv: typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_IV),
            mode: typeCode.mode.CBC
            });
        return cipher.toString()
    } catch(error) {
        cipher = typeCode.AES.encrypt(JSON.stringify(planeText), typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_KEY), {
            iv: typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_IV),
            mode: typeCode.mode.CBC
            });
        return cipher.toString()
    }
}

function decrypt(code, url) {
    try {
        if(listUrlKeyDefault.indexOf(url) == -1) {
            var cipher = typeCode.AES.decrypt(code, 
                typeCode.enc.Utf8.parse(Session.get('aes-key')), 
                {
                    iv: typeCode.enc.Utf8.parse(Session.get('aes-iv')),
                    mode: typeCode.mode.CBC
                });
        } else {
            cipher = typeCode.AES.decrypt(code, 
                typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_KEY), 
                {
                    iv: typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_IV),
                    mode: typeCode.mode.CBC
                });
        }
        if(cipher == null || cipher == "") {
            throw "decode error"
        }
        var result = typeCode.enc.Utf8.stringify(cipher).toString();
        return result;
    }catch (error) {
        if(typeof code == 'object') {
            return JSON.stringify(code)
        }
        return ApiManager.updateKeyAES()
    }
}

function encryptSession(json) {
    var cipher = typeCode.AES.encrypt(JSON.stringify(json), typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_KEY_SESSION), {
        iv: typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_IV_SESSION),
        mode: typeCode.mode.CBC
        });
    return cipher.toString()
}

function decryptSession(code) {
    var cipher = typeCode.AES.decrypt(code, typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_KEY_SESSION), {
        iv: typeCode.enc.Utf8.parse(process.env.VUE_APP_AES_IV_SESSION),
        mode: typeCode.mode.CBC
        });
    return typeCode.enc.Utf8.stringify(cipher).toString()
}

var AWS = require('aws-sdk');
const util = require('util');

var creds = new AWS.Credentials({
    'accessKeyId': process.env.VUE_APP_AWS_ACCESS_KEY_ID,
    'secretAccessKey': process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
    'sessionToken': null
})
AWS.config = new AWS.Config({
    'region':'ap-southeast-2',
    'credentials': creds
})

var kms = new AWS.KMS({apiVersion: '2014-11-01'});

async function encryptPass(pass) {
    try {
        var encryptionParams = {
            KeyId: Session.get('key-login'),
            Plaintext: pass
        };
        let kmsEncrypt = util.promisify(kms.encrypt).bind(kms);
        let encryptedData = await kmsEncrypt(encryptionParams);

        // var result = encryptedData.CiphertextBlob.data.toString('base64');
        let buff = new Buffer(encryptedData.CiphertextBlob);
        let result = buff.toString('base64');
        Logs.string('encode', result)
        return result;
    } catch (error) {
        var errorData;
        if (error.response != undefined && error.response.data != undefined ) {
            errorData = error.response.data;
            Logs.json("Error data", errorData);
        } else {
            errorData = error;
            Logs.json("Error", error);
        }
        Vue.$toast.error(errorData.detail == undefined ? errorData : errorData.detail, {
            position: "top",
            duration: this.duration,
        });
        return null
    }
}

// function decryptPass(code) {
//     return new Promise(async (success, reject) => {
//         try {
//             var result = [];
//             for (var i = 0; i < code.length; i++) {
//                 result.Push(code.charCodeAt(i));
//             }
//             var decryptionParams = {
//                 CiphertextBlob : result
//             };
//             let kmsDecrypt = util.promisify(kms.decrypt).bind(kms);
//             let decryptedData = await kmsDecrypt(decryptionParams);
//             //ndecryptedData contained 2 parts, Plaintext and KeyId
//             Logs.json('decryptedData', decryptedData);
//             Logs.string('Plaintext', decryptedData.Plaintext);
//             Logs.string('KeyId', decryptedData.KeyId);
//             success(decryptedData.Plaintext);
//         } catch (error) {
//             Logs.json(error.response)
//             reject(error)
//         }
//     })
// }

export {
    encrypt,
    decrypt,
    encryptSession,
    decryptSession,
    encryptPass,
    // decryptPass
}