<template>
<modal name="modal-tryhandshake" @before-open="beforeOpen" with="auto" height="auto" style="z-index: 3000">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-md">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content" style="height: 400px">
                        <div class="modal-header" style="background: #ff0000ad;">
                            <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                <span style="color: white;" class="ng-binding"><i class="icon-remove-sign"></i>  No connection</span>
                            </h5>
                        </div>
                        <div class="modal-body" style="padding:0; display: grid">
                            <div style="background: #ff0000ad; padding-bottom: 10px">
                                <img style="width: 100px" src="/assets/img/network-error-icon-28.jpg" />
                            </div>
                            <div class="content" style="justify-content: center;">
                                Handshake error!
                            </div>
                            <div class="text-danger">{{message}}</div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" data-dismiss="modal" type="button" v-on:click="tryAgain()">Try Again</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import ApiManager from "@/api/ApiManager";
import * as Logs from '@/utils/Logs';
import * as Session from '@/utils/SessionStore';

export default {
    name: "TryHandShake",
    props: [],
    components: {},
    data() {
        return {
            message: ""
        };
    },
    methods: {
        beforeOpen(event) {
            Session.set('hasGetHandshake', false)
            this.setMessage(event.params.error)
        },
        tryAgain() {
            this.$root.$refs.AppCyrus.start();
            this.message = "";
            Session.set('hasGetHandshake', true)
            ApiManager.getHandShake().then((response) => {
                Session.set('hasGetHandshake', false)
                Logs.json('Try-Handshake', response);
                this.$root.$refs.AppCyrus.finish();
                this.closeModal();
                this.$router.go(); // reload page
            }).catch((error) => {
                Session.set('hasGetHandshake', false)
                Logs.json('Try-Handshake', error);
                this.setMessage(error)
                this.$root.$refs.AppCyrus.fail()
            });
        },
        closeModal() {
            this.$modal.hide('modal-tryhandshake');
        },
        setMessage(error) {
            if(error.status == 403) {
                this.message = "Your client is not authorized! You may try to request permission again";
            } else if(error.detail == undefined){
                this.message = error;
            } else {
                this.message = error.detail
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
