import ApiManager from "@/api/ApiManager";
import {
  GET_INFOR_SUMMARY,
  GET_EMPLOYEE_CONTACT,
  GET_EMPLOYEE_REMUNERATION,
  GET_LIST_EMPLOYEE_REPORT,
  GET_LEAVEBALALCES,
  GET_PAYROLL_ACTIVITY_SUMMARY,
  GET_INFOR_TOP_INCOME,
  GET_LAST_6_MONTH_PAYROLL_SUMMARY,
  GET_LAST_THREE_MONTH_PAYROLL,
  GET_YEARLY_PAYROLL_REPORT,
  GET_PAYROLL_SUMMARY_REPORT
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
  [GET_INFOR_SUMMARY](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/InforSummary?companyId=" + request.companyId + "&fromDate=" + request.fromDate + "&toDate=" + request.toDate)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_EMPLOYEE_CONTACT](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/EmployeeContacts?companyId="+ companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_EMPLOYEE_REMUNERATION](context, companyId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/EmployeeRemuneration?companyId="+ companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LIST_EMPLOYEE_REPORT](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/ListEmployee?companyId="+ request.companyId + "&typeListEmployee=" + request.typeListEmployee)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LEAVEBALALCES](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/LeaveBalances?companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&effectiveDate" + request.effectiveDate)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_PAYROLL_ACTIVITY_SUMMARY](context, request) {
    return new Promise((success, reject) => {
      var stringRequest = "companyId=" + request.companyId + "&employeeId=" + request.employeeId + "&typeReport=" + request.typeReport + "&dateRangeStart=" + request.dateRangeStart + "&dateRangeEnd=" + request.dateRangeEnd
      ApiManager.get("/api/Report/PayRollActivitySummary?" + stringRequest)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_INFOR_TOP_INCOME](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/InforTopIncome?companyId=" + request.companyId + "&fromDate=" + request.fromDate + "&toDate=" + request.toDate + "&size=" + request.size)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LAST_6_MONTH_PAYROLL_SUMMARY](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/Last6MonthPayRoll?companyId=" + request.companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_LAST_THREE_MONTH_PAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/LastThreeMonthPayRoll?companyId=" + request.companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_YEARLY_PAYROLL_REPORT](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/YearlyPayrollReport?companyId=" + request.companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_PAYROLL_SUMMARY_REPORT](context, request) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/Report/PayrollSummaryReport?companyId=" + request.companyId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
