import ApiManager from "@/api/ApiManager";
import {
    ADD_NEW_RUNPAYROLL,
    REVIEW_RUNPAYROLL,
    DRAFT_RUNPAYROLL,
    SUBMIT_RUNPAYROLL,
    GETLIST_RUNPAYROLL,
    DELETE_RUNPAYROLL,
    DETAIL_RUNPAYROLL,
    PAYSLIP_FOR_RUNPAYROLL,
    PAYSLIP_FOR_EMPLOYEE,
    SEND_PAYSLIP,
    SEND_PAYSLIP_FOR_EMPLOYEE,
    SEND_PAYSLIP_FOR_PAYROLL,
    STP_RUNPAYROLL,
    GET_STP_STATUS,
    GET_STP_RESULT,
    GET_DETAIL_PAYROLL,
    UPDATE_RUNPAYROLL,
    DOWNLOAD_XML,
    DOWNLOAD_ABA,
    GET_ITEMS_FROM_PREVIOUS_PAYROLL
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
  [ADD_NEW_RUNPAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/CreateTemplate", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [REVIEW_RUNPAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/Review", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DRAFT_RUNPAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/SaveDraft", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SUBMIT_RUNPAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/Submit", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GETLIST_RUNPAYROLL](context, modelRequest) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/List?companyId=" + modelRequest.companyId + "&fromDate=" + modelRequest.fromDate + "&toDate=" + modelRequest.toDate)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_RUNPAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.delete("/api/PayRoll/Delete?companyId=" + request.companyId + "&payrollId=" + request.id)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DETAIL_RUNPAYROLL](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/Detail?payrollId=" + payrollId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PAYSLIP_FOR_RUNPAYROLL](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/PaySlip/PayslipForPayRoll?payrollId=" + payrollId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PAYSLIP_FOR_EMPLOYEE](context, payrollDetailId) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/PaySlip/PayslipForEmployee?payrollDetailId=" + payrollDetailId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },
  [SEND_PAYSLIP](context, dataFile) {
    return new Promise((success, reject) => {
      ApiManager.sendFile("/api/PaySlip/SendPaySlip", dataFile)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },
  [SEND_PAYSLIP_FOR_EMPLOYEE](context, payrollDetailId) {
    return new Promise((success, reject) => {
      ApiManager.sendFile("/api/PaySlip/SendPaySlipForEmployee?payrollDetailId=" + payrollDetailId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },
  [SEND_PAYSLIP_FOR_PAYROLL](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.sendFile("/api/PaySlip/SendPaySlipForPayroll?payrollId=" + payrollId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },
  [STP_RUNPAYROLL](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/STPReport/SendATO?payrollId=" + payrollId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },

  [GET_STP_STATUS](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/STPReport/StatusATO?payrollId=" + payrollId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },

  [GET_STP_RESULT](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/STPReport/ResultATO?payrollId=" + payrollId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },
  [GET_DETAIL_PAYROLL](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/Detail?payrollId=" + payrollId)
        .then((response) => {
          success(response);
      }).catch((error) => {
          reject(error);
      });
    });
  },
  [UPDATE_RUNPAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/Update", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DOWNLOAD_XML](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.downloadFileXML("/api/STPReport/DownloadXML?payrollId=" + payrollId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DOWNLOAD_ABA](context, payrollId) {
    return new Promise((success, reject) => {
      ApiManager.downloadFileABA("/api/PaySlip/ABAFileForPayroll?payrollId=" + payrollId)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
   [GET_ITEMS_FROM_PREVIOUS_PAYROLL](context, request) {
    return new Promise((success, reject) => {
      ApiManager.post("/api/PayRoll/getIdOfPreviousPayroll", request)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
