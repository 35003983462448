import ApiManager from "@/api/ApiManager";
import {
  SEARCH_SUPERFUND,
  DETAIL_SUPERFUND
} from "../actionsType.js";

// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
  [SEARCH_SUPERFUND](context, request) {
    var keySearch = ""
    var superfund = request.keySearch.split(" ").join("")
    
    if (!isNaN(superfund) && superfund.length == 11) {
      keySearch = request.keySearch.split(" ").join("")
    } else {
      keySearch = request.keySearch
    }
    return new Promise((success, reject) => {
      ApiManager.get("/api/SuperFund/Search/Basic?keySearch=" + keySearch + "&page=" + request.page + "&lenght=" + request.lenght)
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DETAIL_SUPERFUND](context, keyabn) {
    return new Promise((success, reject) => {
      ApiManager.get("/api/SuperFund/Detail?keyABN=" + keyabn)
        .then((response) => {
          success(response);
        })
        .catch((error) => { 
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
