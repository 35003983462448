// Accountant
export const ACCOUNTANTS = "accountants";
export const DELETE_ACCOUNTANT = "deleteAccountant";
export const ADD_ACCOUNTANT_BY_EMAIL = "addAccountantsByEmail";
export const ADD_ACCOUNTANT = "addAccountant";
//Employees
export const GET_EMPLOYEE = "getEmployee";
export const GET_INDIVIDUAL_EMPLOYEE = "getIndividualEmployee";
export const ADD_EMPLOYEE = "addEmployee";
export const ADD_EMPLOYEE_BY_EMAIL = "addEmployeeByEmail";
export const ADD_EMPLOYEE_DEMO = "addEmployeeDemo";
export const DELETE_EMPLOYEE = "deleteEmployee";
export const UPDATE_EMPLOYEE = "updateEmployee";
export const UPDATE_EMPLOYEE_BY_EMAIL = "updateEmployeeByEmail";
export const DETAIL_LEAVE = "detailLeave";
export const CHECK_EMAIL = "checkEmail";
export const CHECK_CONTACT_NUMBER = "checkContactNumber";
export const CHECK_TAX_NUMBER = "checkTaxNumber";
export const DATA_STEP0 = "DataStep0";
export const DATA_STEP1 = "DataStep1";
export const DATA_STEP2 = "DataStep2";
export const DATA_STEP3 = "DataStep3";
export const DATA_STEP4 = "DataStep4";
export const DELETEDATA = "Deletedata";

// Auth
export const REGISTER_ACCOUNT = "registerAccount";
export const LOGIN = "login";
export const SEND_EMAIL_COMFIRM = "sendEmailComfirm";
export const CHECK_TOKEN_REGISTER = "checkTokenRegister";
export const COMFIRM_EMAIL = "comfirmEmail";
export const SEND_EMAIL_FORGOT_PASSWORD = "sendEmailForgotPassword";
export const CHECK_TOKEN_FORGOT_PASSWORD = "checkTokenForgotPassword";
export const FORGOT_PASSWORD_EMAIL = "forgotPasswordEmail";
export const LOGOUT = "logout";
export const LOGIN_WITH_GOOGLE = "loginWithLogin";
export const CHECK_GOOGLE_REGISTER = "checkGGRegister"
export const CHECK_CODE_ENCODE = "checkCodeEncode";

// Business
export const SEARCH_BUSINESS = "searchBesiness";
export const DETAIN_BUSINESS = "detailBesiness";

// Company
export const ADD_COMPANY = "addCompany";
export const GET_LIST_COMPANY = "getListCompany";
export const SEARCH_COMPANY = "searchCompany"; 
export const DETAIL_COMPANY = "detailCompany"; 
export const UPDATE_COMPANY = "updateCompany";
export const MOVE_TO_TRASH = "moveToTrashCompany";
export const RESTORE = "restoreCompany";
export const DELETE_COMPANY = "deleteCompany";
export const CHECK_COMPANY_NAME = "checkCompanyName";

// Super Fund
export const SEARCH_SUPERFUND = "searchSuperFund";
export const DETAIL_SUPERFUND = "detailSuperFund";

// Run Payroll
export const ADD_NEW_RUNPAYROLL = "addNewRunPayroll";
export const REVIEW_RUNPAYROLL = "reviewRunPayroll";
export const DRAFT_RUNPAYROLL = "draftRunPayroll";
export const SUBMIT_RUNPAYROLL = "subRunPayroll";
export const GETLIST_RUNPAYROLL = "getListRunPayroll";
export const DELETE_RUNPAYROLL = "deleteRunPayroll";
export const DETAIL_RUNPAYROLL = "detailRunPayroll";
export const PAYSLIP_FOR_RUNPAYROLL = "payslipForRunPayroll";
export const PAYSLIP_FOR_EMPLOYEE = "payslipForEmployee";
export const SEND_PAYSLIP = "sendPayslip";
export const SEND_PAYSLIP_FOR_EMPLOYEE = 'sendPayslipForEmployee';
export const SEND_PAYSLIP_FOR_PAYROLL = 'sendPayslipForPayroll'
export const STP_RUNPAYROLL = "stpRunPayRoll";
export const GET_STP_STATUS = "getSTPStatus";
export const GET_STP_RESULT = "getSTPResult";
export const GET_DETAIL_PAYROLL = "getDetailPayroll";
export const UPDATE_RUNPAYROLL = "updateRunPayroll";
export const DOWNLOAD_XML = "downloadXML";
export const DOWNLOAD_ABA = "downloadABA";
export const GET_ITEMS_FROM_PREVIOUS_PAYROLL = "getItemsFromPreviousPayroll";

// Timesheet
export const GET_LIST_EMPLOYEE_LEAVE = "getListEmployeeLeave";
export const GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY = "getListEmployeeLeaveEmployeeOnly";
export const ADD_LEAVE = "addLeave";
export const UPDATE_LEAVE = "updateLeave";
export const GET_LIEST_LEAVE = "getListLeave";
export const DELETE_LEAVE = "deleteLeave";
export const GET_LIST_HOLIDAYS = "getListHolidays";
export const GET_EMPLOYEELIST_LEAVE = "getEmployeeListLeave";
//Report
export const GET_INFOR_SUMMARY = "getInforSummary";
export const GET_EMPLOYEE_CONTACT = "getEmployeeContacts";
export const GET_EMPLOYEE_REMUNERATION = "getEmployeeRemuneration";
export const GET_LIST_EMPLOYEE_REPORT = "getListEmployeeReport";
export const GET_LEAVEBALALCES = "getLeaveBalances";
export const GET_PAYROLL_ACTIVITY_SUMMARY = "getPayrollActivitySummary";
export const GET_INFOR_TOP_INCOME = "getInforTopIncome";
export const GET_LAST_6_MONTH_PAYROLL_SUMMARY = "getLast6MonthPayRollSummary";
export const GET_LAST_THREE_MONTH_PAYROLL = "getLastThreeMonthPayRoll";
export const GET_YEARLY_PAYROLL_REPORT = "getYearlyPayrollReport";
export const GET_PAYROLL_SUMMARY_REPORT = "getPayrollSummaryReport";

// Profile
export const CHECK_CODE = "checkCode2fa";
export const GET_CODE = "getCode2fa";
export const VERIFY_CODE = "verifyCode2fa";
export const REMOVE_CODE = "removeCode2fa";
export const RESET_PASSWORD = "resetPassword";
export const UPDATE_PROFILE = "updateProfile";
export const USER_INFO = "UserInfo";

// Notification
export const SAVE_DEVICE = "saveDevice";
export const REMOVE_DEVICE = "removeDevice";
export const LIST_DEVICE = "listDevice";

// Feed Back
export const ADD_FEED_BACK = "feedBack";

// Subscription
export const GET_ACTIVE_PLANS= "getActivePlans";
export const GET_CURRENT_SUBSCRIPTION= "getCurrentSubscription";
export const GET_LAST_SUBSCRIPTION= "getLastSubscription";
export const SEND_TOKEN_TO_SERVER= "sendTokenToServer";
export const CREATE_STRIPE_CUSTOMER= "createStripeCustomer";
export const GET_SUBSCRIPTION_LIST= "getSubscriptionList";
export const DEACTIVATE_SUBSCRIPTION= "deactivateSubscription";
export const INITIATE_GOCARDLESS_PAYMENT= "initiateGoCardlessPayment";
export const GOCARDLESS_SEND_SUCCESS_REQUEST= "GoCardlessSendSuccessRequest";
export const HOLD_SUBSCRIPTION= "HoldSsubscription";