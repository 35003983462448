import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui'
import employees from "./modules/employeesModule";
import auth from "./modules/authModule";
import business from "./modules/businessModule";
import company from "./modules/companyModule";
import superFund from "./modules/superFundModule";
import payrollModule from "./modules/payrollModule";
import leaveModule from "./modules/leaveModule";
import reportModule from "./modules/reportModule";
import profileModule from "./modules/profileModule";
import notificationModule from "./modules/notificationModule";
import paymentModule from './modules/paymentModule';
import accountantModule from './modules/accountantModule';
import subscriptionModule from './modules/subscriptionModule';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        ui,
        employees,
        auth,
        business,
        company,
        superFund,
        payrollModule,
        leaveModule,
        reportModule,
        profileModule,
        notificationModule,
        paymentModule,
        accountantModule,
        subscriptionModule
    }
})