import ApiPaymentManager from "@/api/ApiPaymentManager";
import PaymentAction from "../actionTypes/paymentActionType";
// import Token from "@/api/Token";

const state = {
    paymentInfo: {},
    customer: {},
    card: {},
    isGetInfoCard: false
    // plans: [],
};

const getters = {
    getCustomer: (state) => {
        return state.customer;
    },
    getPaymentInfo: (state) => {
        return state.paymentInfo;
    },
    getCard: (state) => {
        return state.card
    },
    isGetInfoCard: (state) => {
        return state.isGetInfoCard
    }
    // getPlans: (state) => {
    //     return state.plans;
    // },
    // getCurrentPlan: (state) => {
    //     return state.paymentInfo.plan;
    // },
};

const mutations = {
    setCustomer(state, customer) {
        state.customer = customer;
        state.isGetInfoCard = true
        if (customer.card !== undefined) {
            state.card = customer.card
        }
    },
    setPaymentInfo(state, paymentInfo) {
        // if (paymentInfo === null) {
        //     state.plans[0].selected = true;
        // } else {
        state.paymentInfo = paymentInfo;
        //     if (paymentInfo.plan !== undefined) {
        //         state.plans.forEach((planItem) => {
        //             planItem.selected = planItem.id === paymentInfo.plan.id;
        //         });
        //     }
        // }
    },
    removePaymentInfo(state) {
        state.paymentInfo = {};
    },
    // setPlans(state, plans) {
    //     state.plans = plans;
    //     state.plans.forEach((planItem) => {
    //         planItem["selected"] = false;
    //     });
    // },
    // changePlan(state, planId) {
    //     const plan = state.plans.find((p) => p.id === planId);
    //     state.plans.forEach((planItem) => {
    //         planItem["selected"] = planItem.id === planId;
    //     });
    //     state.paymentInfo.plan = plan;
    // },
    updateCard(state, card) {
        state.card = card
    },
};

const actions = {
    [PaymentAction.CONFIG_INFO]() {
        return new Promise((success, reject) => {
            ApiPaymentManager.get("/api/v1/configInfo")
                .then((response) => {
                    success(response);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },

    [PaymentAction.VALIDATE_TRIAL](context, params) {
        return new Promise((success, reject) => {
            ApiPaymentManager.post("/api/v1/customers/validateTrial", params)
                .then((response) => {
					context.commit('setCustomer', response.data.customer);
                    success(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    [PaymentAction.PAYMENT_INFO](context, params) {
        return new Promise((success, reject) => {
            ApiPaymentManager.get("/api/v1/customers/paymentInfo", params)
                .then((response) => {
                    context.commit(
                        "setPaymentInfo",
                        response.data.paymentInfo
                    );
                    success(response);
                })
                .catch((error) => {
                    context.commit("removePaymentInfo");
                    reject(error.response);
                });
        });
    },

    [PaymentAction.UPDATE_CARD](context, params) {
        return new Promise((success, reject) => {
            ApiPaymentManager.put("/api/v1/customers/updateCard", params)
                .then((response) => {
                    if (response.data.customer.card !== undefined) {
                        context.commit(
                            "updateCard",
                            response.data.customer.card
                        );
                    }
                    success(response);
                })
                .catch((error) => {
                    reject(error.response);
                });
        });
    },

    // [PaymentAction.PLANS](context, params) {
    //     return new Promise((success, reject) => {
    //         ApiPaymentManager.get("/api/v1/plans", params)
    //             .then((response) => {
    //                 context.commit("setPlans", response.data.plans);
    //                 success(response);
    //             })
    //             .catch((error) => {
    //                 reject(error.response);
    //             });
    //     });
    // },

    // [PaymentAction.ACTIVE_SUBSCRIPTION_ACCOUNT](context, params) {
    //     return new Promise((success, reject) => {
    //         ApiPaymentManager.post("/api/v1/customers", params)
    //             .then((response) => {
    //                 success(response);
    //             })
    //             .catch((error) => {
    //                 reject(error.response);
    //             });
    //     });
    // },

    // [PaymentAction.SUBSCRIPTIONS](context, params) {
    //     return new Promise((success, reject) => {
    //         ApiPaymentManager.get("/api/v1/subscriptions", params)
    //             .then((response) => {
    //                 success(response);
    //             })
    //             .catch((error) => {
    //                 reject(error.response);
    //             });
    //     });
    // },

    // [PaymentAction.SUBSCRIBE](context, params) {
    //     return new Promise((success, reject) => {
    //         ApiPaymentManager.post("/api/v1/subscriptions", params)
    //             .then((response) => {
    //                 context.commit("changePlan", params.plan);
    //                 success(response);
    //             })
    //             .catch((error) => {
    //                 reject(error.response);
    //             });
    //     });
    // },

    // [PaymentAction.SUBSCRIPTION_TRIAL](context, params) {
    //     return new Promise((success, reject) => {
    //         ApiPaymentManager.post("/api/v1/user/subscription/trial", params)
    //             .then((response) => {
    //                 //   Token.saveToken(response.accessToken);
    //                 //   Token.saveRefreshToken(response.refreshToken);
    //                 success(response);
    //             })
    //             .catch((error) => {
    //                 reject(error.response);
    //             });
    //     });
    // },

    // [PaymentAction.CANCEL_SUBSCRIPTION](context, params) {
    //     return new Promise((success, reject) => {
    //         ApiPaymentManager.put("/api/v1/subscriptions/unsubscribe", params)
    //             .then((response) => {
    //                 success(response);
    //             })
    //             .catch((error) => {
    //                 reject(error.response);
    //             });
    //     });
    // },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
