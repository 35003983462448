import * as Session from '@/utils/SessionStore'

const ID_TOKEN_KEY = "id_token";
const TOKEN_FCM = "token_fcm";
const REFRESH_TOKEN = "refreshToken";
const CODE_HANDSHAKE = "CODE_HANDSHAKE";

// Logout
export const destroySession = () => {
    Session.destroy();
};

// Token
export const getToken = () => {
    if (Session.has(ID_TOKEN_KEY)) {
        return Session.get(ID_TOKEN_KEY);
    }
    return undefined;
};

export const saveToken = token => {
    Session.set(ID_TOKEN_KEY, token);
};

export const removeToken = () => {
    if (Session.has(ID_TOKEN_KEY)) {
        Session.remove(ID_TOKEN_KEY);
    }
};

// RefreshToken
export const getRefreshToken = () => {
    if (Session.has(REFRESH_TOKEN)) {
        return Session.get(REFRESH_TOKEN);
    }
    return undefined;
};

export const saveRefreshToken = refreshToken => {
    Session.set(REFRESH_TOKEN, refreshToken);
};

export const removeRefreshToken = () => {
    if (Session.has(REFRESH_TOKEN)) {
        Session.remove(REFRESH_TOKEN);
    }
};

// handShake
export const getCodeHandShake = () => {
    if (Session.has(CODE_HANDSHAKE)) {
        return Session.get(CODE_HANDSHAKE);
    }
    return undefined;
};

export const saveCodeHandShake = code => {
    Session.set(CODE_HANDSHAKE, code);
    var date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    Session.set('dateGetHandshake', date);
};

export const removeCodeHandShake = () => {
    if (Session.has(CODE_HANDSHAKE)) {
        Session.remove(CODE_HANDSHAKE);
    }
};

// TokenFCM
export const getTokenFCM = () => {
    if (Session.has(TOKEN_FCM)) {
        return Session.get(TOKEN_FCM);
    }
    return undefined;
};

export const saveTokenFCM = token => {
    Session.set(TOKEN_FCM, token);
};

export const removeTokenFCM = () => {
    if (Session.has(TOKEN_FCM)) {
        Session.remove(TOKEN_FCM);
    }
};

export default {
    destroySession,
    getToken,
    saveToken,
    removeToken,
    getRefreshToken,
    saveRefreshToken,
    removeRefreshToken,
    getCodeHandShake,
    saveCodeHandShake,
    removeCodeHandShake,
    getTokenFCM,
    saveTokenFCM,
    removeTokenFCM,
};
