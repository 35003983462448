// detect a click outside of an element, this is useful for closing modals, dropdowns, etc.
export default {
  bind(el, binding) {
    el.clickOutsideEvent = function (event) {
      // Check if the click is outside the element
      if (!(el == event.target || el.contains(event.target))) {
        // If the click is outside, call the method provided in the directive's binding
        binding.value(event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};