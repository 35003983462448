<template>
  <div class="app" @mousemove="idleReset">
    <modal
      name="idle-modal"
      @before-open="beforeOpen"
      @opened="newDirtyForm"
      with="50%"
      height="auto"
    >
      <div
        uib-modal-window="modal-window"
        class="modal fade ng-scope ng-isolate-scope show"
        role="dialog"
        aria-labelledby="modal-title"
        aria-describedby="modal-body"
        size="md"
        index="0"
        animate="animate"
        ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}"
        tabindex="-1"
        uib-modal-animation-class="fade"
        modal-in-class="show"
        modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto"
      >
        <div class="modal-dialog dialogModalCustom">
          <div class="modal-content customIdleModal" uib-modal-transclude="">
            <div
              class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              "
            >
              <div class="modal-content">
                <div class="modal-header" style="padding-top: 25px;">
                  <h5
                    class="modal-title m-auto"
                    style="text-align: center"
                  >
                    <i class="fa fa-power-off" style="color:#6554E0;font-size:22px" aria-hidden="true"></i>
                  </h5>
                 
                </div>
                <v-app>
                  <div class="modal-body pt-0">
                    <div class="d-block text-center">
                      <h6>
                        You're Idle. Do Something!
                        <br/>
                        You'll be logged out in
                        <span style="color:#6554E0">{{ UICountdown }} </span> second(s).
                      </h6>
                    </div>
                    
                  </div>
                </v-app>
                <div class="modal-footer pt-0" style="padding-bottom: 25px;">
                  <div class="col-lg-12 text-center">
                   
                    <button class="mt-0 btn btn-primary" block @click="UIReset">
                      Stay logged in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <TryHandShake />
    <router-view />
    <vue-progress-bar />

  </div>
</template>

<script>
import Vue from "vue";
import _initializeFirebaseMessage from "./pushNotification";
import _initializeFirebaseRemoteConfig from "./remoteConfig";
import TryHandShake from "./views/home/base/modal/TryHandShake";
import ApiManager from "./api/ApiManager";
import Token from "./api/Token";
import * as Logs from "./utils/Logs";
import * as Session from "./utils/SessionStore";
import { REMOVE_DEVICE, LOGOUT } from "./store/actionsType";

export default {
  name: "AppCashbook",
  components: {
    TryHandShake,
  },
  created() {
    _initializeFirebaseMessage();
    _initializeFirebaseRemoteConfig();
    
    this.$root.$refs.AppCyrus = this;
    this.isLoadPage = true;
    this.setLoadPage();
  },
  data() {
    return {
      username: "",
      avatar: "",
      uid: "",
      btnNot: [],
      btns: [],
      isLoadPage: true,
      isDisabled: false,
      countApi: 0,
      idleTime: 300, // The internal timer that tracks idle time, is reset on event. Set this to your timeout value in seconds
      idleCountdown: null, // Populated at runtime by idleTime value. This value decrease every second until timeout is reached
      idleTimeout: false, // Timeout status toggle
      UITime: 120, // Total time before UI performs action. Set this to your desired countdown to your users
      UICountdown: null, // Populated by uiTime on idleTimeout, UI countdown decreases every second until timeout is reached
      UITimeout: false, // UI timeout status toggle
    };
  },
  beforeMount() {
    if (!Vue.prototype.$session.exists()) {
      Session.start();
    } else if (!Vue.prototype.$session.has("data")) {
      Session.destroyAll();
    }
    var now = new Date();
    now.setDate(now.getDate() - 1);
    if (Session.has("dateLogin")) {
      var dateLogin = new Date(Session.get("dateLogin"));
      if (dateLogin <= now) {
        this.$store
          .dispatch(REMOVE_DEVICE, Session.get("Profile").id)
          .then(() => {
            Token.destroySession();
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {
            if (this.$global.path != "/updateserver") {
              Token.destroySession();
              this.$router.push({
                path: "/login",
              });
            }
          });
      } else {
        this.checkHandShake(now);
      }
    } else {
      this.checkHandShake(now);
    }
  },

  mounted() {
    /// add google map js
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`;
    document.head.appendChild(script);
  },

  beforeUpdate() {
    this.isLoadPage = true;
    this.setLoadPage();
  },

  ready: function () {
    this.startIdleCountdown();
  },

  methods: {
    setLoadPage() {
      setTimeout(() => {
        if (this.countApi == 0) {
          this.isLoadPage = false;
        }
      }, 1000);
    },
    start() {
      if (!this.isLoadPage && !this.isDisabled) {
        this.$Progress.start();
        this.btns = window.$(".btn");
        this.isDisabled = true;
        for (var i = 0; i < this.btns.length; i++) {
          var btn = this.btns[i];
          // Logs.string('btn', btn.innerText)
          if (!btn.disabled && !btn.classList.contains("disabled")) {
            btn.disabled = true;
            btn.classList.add("disabled");
          } else {
            if (this.btnNot.indexOf(btn) == -1) {
              this.btnNot.push(btn);
            }
          }
        }
      }
      this.countApi++;
    },
    finish() {
      if (!this.isLoadPage && !this.isDisabled) {
        this.enebleButton();
        this.$Progress.finish();
      } else {
        this.countApi--;
        if (this.countApi == 0) {
          this.isLoadPage = false;
          if (this.isDisabled) {
            this.isDisabled = false;
            this.enebleButton();
            this.$Progress.finish();
            // Logs.stringKey('finish')
          }
        }
      }
    },

    /**
     * Handles the failure scenario.
     * If the page is not loading and the button is not disabled, it enables the button and shows a failure progress indicator.
     * If the page is loading and the button is disabled, it decrements the count of API calls and checks if all API calls have completed.
     * If all API calls have completed, it sets the page loading flag to false.
     * If the button is disabled, it enables the button and shows a failure progress indicator.
     */
    fail() {
      if (!this.isLoadPage && !this.isDisabled) {
        this.enebleButton();
        this.$Progress.fail();
      } else {
        this.countApi--;
        if (this.countApi == 0) {
          this.isLoadPage = false;
          if (this.isDisabled) {
            this.isDisabled = false;
            this.enebleButton();
            this.$Progress.fail();
          }
        }
      }
    },
    
    enebleButton() {
      for (var i = 0; i < this.btns.length; i++) {
        var btn = this.btns[i];
        if (this.btnNot.indexOf(btn) == -1) {
          btn.disabled = false;
          btn.classList.remove("disabled");
        }
      }
    },
    _showChatFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          xfbml: true,
          version: "v8.0",
        });
      };
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },

    checkHandShake(now) {
      if (!Session.has("hasGetHandshake") || !Session.get("hasGetHandshake")) {
        Session.set("hasGetHandshake", true);
        if (Token.getCodeHandShake() == undefined) {
          this.getHandShake();
        } else {
          if (Session.has("dateGetHandshake")) {
            var dateGetHandShake = new Date(Session.get("dateGetHandshake"));
            if (
              dateGetHandShake <= now ||
              !Session.has("aes-key") ||
              !Session.has("aes-iv")
            ) {
              this.getHandShake();
            } else {
              Logs.stringKey("set false");
              Session.set("hasGetHandshake", false);
              this.checkGetLocation();
            }
          } else {
            this.getHandShake();
          }
        }
      }
    },
    /**
     * Initiates a handshake process with the server and handles both the success and error scenarios.
     * 
     * - Sets the "hasGetLocation" session flag to false.
     * - Calls the ApiManager to initiate a handshake with the server.
     * - If successful, logs the response and reloads the page.
     * - If an error occurs, logs the error and shows a popup to notify the user.
     * 
     * @returns {void}
     */
    getHandShake() {
      Session.set("hasGetLocation", false);
      ApiManager.getHandShake()
        .then((response) => {
          Logs.json("App-Handshake", response);
          this.reloadPage();
        })
        .catch((error) => {
          Logs.json("App-Handshake Error", error);
          this.showPopup();
        });
    },
    reloadPage() {
      Logs.stringKey("set false");
      Session.set("hasGetHandshake", false);
      if (this.$global.path != "/login" && this.$global.path != "/register") {
        this.$router.go();
      } else {
        this.checkGetLocation();
      }
    },
    checkGetLocation() {
      if (!Session.has("hasGetLocation") || !Session.get("hasGetLocation")) {
        ApiManager.getLocation();
      }
    },
    showPopup(error) {
      if (this.$global.path != "/updateserver") {
        Vue.prototype.$modal.show("modal-tryhandshake", {
          error: error,
        });
      }
    },

    // ************************************* START IDLE COUNTDOWN ************************************* //
    startIdleCountdown: function () {
      this.$modal.hide("idle-modal");     
      this.idleCountdown = this.idleTime;
      this.setIdleTimer = setInterval(this.idleTimer, 1000); // starts the timer. setTimer is used to clear/reset idleTimer
    },

    idleTimer: function () {
      this.idleCountdown = this.idleCountdown - 1; 
      if (!this.idleCountdown) {
        clearInterval(this.setIdleTimer); // clear timer
        this.idleTimeout = !this.idleTimeout; // toggle the idle timeout status
        this.startUICountdown(); // start the UI timer
      }
    },

    idleReset: function () {
      if (
        !this.idleTimeout &&
        this.$global.path != "/login" &&
        this.$global.path != "/register"
      ) {
        clearInterval(this.setIdleTimer); // reset the UI timer
        this.startIdleCountdown(); // start the idle countdown
      }
    },

  
    startUICountdown: function () {
      
       this.$modal.show("idle-modal");
      this.UICountdown = 120; // initialize the UI timer with UI timeout config value
      this.setUITimer = setInterval(this.UITimer, 1000); // start UI timer. setUITimer is used to reset UITimer
    },

    // the countdown timer decrements the UICountdown value until zero
    UITimer: function () {
      this.UICountdown = this.UICountdown - 1; // decrement UI countdown every second
      if (!this.UICountdown) {
        // the countdown is complete, take action
        clearInterval(this.setUITimer); // reset the UI timer
        this.UITimeout = !this.UITimeout; // toggle UI timeout status. Use for future events
        this.countdownExpired(); // post UI countdown expiry events
      }
    },

    // If the UI reset button is clicked, we start from the beginning
    UIReset: function () {
      this.closeModal();
      clearInterval(this.setUITimer); // reset the UI timer
      this.idleTimeout = !this.idleTimeout; // Toggle idleTimeout status
      this.startIdleCountdown(); // start the idle countdown
    },


    countdownExpired: function () {
      Session.remove("Username");
      Session.remove("currenCompanyId");
      Session.remove("currenCompanyName");
      Session.remove("currenCompany");
      Session.remove("workHours");
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(LOGOUT, Token.getRefreshToken())
        .then(() => {
          this.$store.dispatch(REMOVE_DEVICE, Session.get("Profile").id);
          Token.destroySession();
          this.$root.$refs.AppCyrus.finish();
          this.clearTimeout();
          window.location.href = "login";
          this.closeModal();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    closeModal() {
      this.$modal.hide("idle-modal");
    },
  },
};
</script>

<style scoped>
@import url("/assets/fonts/fontawesome-all.min.css");
/* @import '/assets/css/themes.css'; */

.app {
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system,
    system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e4b5b;
  text-align: center;
  background-color: white;
  margin: 0;
  padding: 0;
}

#nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
  height: 50px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.customIdleModal {
   position: absolute;
    top: 0;
    margin-top: -22px;
}

.dialogModalCustom {
   max-width:550px;
}

</style>
