import Vue from 'vue'
import * as Encode from './Encodepassword'
// import * as Logs from './Logs'

var required = [
    'CODE_HANDSHAKE',
    '2FAToken',
    'cache_ipClient',
    'cache_address',
    'dateGetHandshake',
    'key-login',
    'aes-key',
    'aes-iv',
    'hasGetLocation'
]

function get(name) {
    // Logs.string('json', Vue.prototype.$session.get('data'))
    var session = {}
    var data = Encode.decryptSession(Vue.prototype.$session.get('data'))
    if(data != "") {
        session = JSON.parse(data)
        // Logs.json('data', session)
        return session[name]
    } else {
        return null
    }
    
}

function set(name, value) {
    // Logs.string('json', Vue.prototype.$session.get('data'))
    var session = {}
    var data = Encode.decryptSession(Vue.prototype.$session.get('data'))
    if(data != "") {
        session = JSON.parse(data)
        // Logs.json('data', session)
    }
    session[name] = value
    Vue.prototype.$session.set('data', Encode.encryptSession(session))
}

function has(name) {
    // Logs.string('json', Vue.prototype.$session.get('data'))
    var data = Encode.decryptSession(Vue.prototype.$session.get('data'))
    if(data != "") {
        var session = JSON.parse(data)
        // Logs.json('data', session)
        if(session[name] != undefined) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
    
}

function remove(name) {
    
    // Logs.string('json', Vue.prototype.$session.get('data'))
    var session = {}
    var data = Encode.decryptSession(Vue.prototype.$session.get('data'))
    if(data != "") {
        session = JSON.parse(data)
        // Logs.json('data', session)
        delete session[name]
        Vue.prototype.$session.set('data', Encode.encryptSession(session))
    }
}

function destroy() {
    var session = {}
    required.forEach(element => {
        if(has(element)) {
            session[element] = get(element)
        }
    });
    destroyAll()
    Vue.prototype.$session.set('data', Encode.encryptSession(session))
}

function destroyAll() {
    Vue.prototype.$session.destroy()
    start()
}

function start() {
    if(Vue.prototype.$session.has('data')) {
        Vue.prototype.$session.destroy()
    }
    Vue.prototype.$session.start()
    Vue.prototype.$session.set('data', "")
}

export {
    get,
    set,
    has,
    remove,
    destroy,
    destroyAll,
    start
}